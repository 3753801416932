import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { Row, Col, Media, Modal, ModalBody } from 'reactstrap';
import MasterProductDetail from './MasterProductDetail';
import { getImageDownloadUrl } from '../../../utils/images';
import CartContext from '../../../utils/cart';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../../utils/currency';
import StoreContext from '../../../utils/store/StoreContext';
import { toast } from 'react-toastify';

const ProductItem = ({
  product,
  des,
  cartClass,
  productDetail,
  title,
}) => {
  const { t } = useTranslation('common');
  const { store, isOpen } = useContext(StoreContext);
  const cartContext = useContext(CartContext);
  const [quantityStr, setQuantityStr] = useState(cartContext.quantity);
  const plusQty = cartContext.plusQty;
  const minusQty = cartContext.minusQty;
  const quantity = cartContext.quantity;
  const setQuantity = cartContext.setQuantity;

  useEffect(() => {
    setQuantityStr(quantity);
  }, [quantity]);
  
  const getFirstVariation = () => {
    if (!product.variations || product.variations.length === 0) {
      return null;
    }
    if (product.stockControl) {
      return product.variations.find((e) => e.enabled && e.currentStock > 0);
    } else {
      return product.variations.find((e) => e.enabled);
    }
  };
  const hasVariations = () => {
    return product.variations && product.variations.length > 0;
  };
  const [currentVariation, setCurrentVariation] = useState(getFirstVariation());

  const [image, setImage] = useState('');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const uniqueTags = [];

  let discount;
  if (product.promoPrice) {
    discount = parseInt(
      ((product.salePrice - product.promoPrice) / product.salePrice) * 100
    );
  }

  const changeQty = (e) => {
    let targetValue = e.target.value;
    targetValue = targetValue.replaceAll(",", ".");
    targetValue = targetValue.replace(/[^0-9.]/g, '');
    let value;
    if(product.sellBy == "u"){
      value = parseInt(targetValue) || 0;
    }else{
      value = parseFloat(targetValue) || 0.0;
    }
    if(product.stockControl){
      if(hasVariations()){
        if(currentVariation.currentStock < value){
          toast.warning(t('Quantity Unavailable'));
          return;
        }
      }else{
        if(product.currentStock < value){
          toast.warning(t('Quantity Unavailable'));
          return;
        }
      }
    }
    setQuantity(value);
    setQuantityStr(targetValue);
  };

  const variantChangeByColor = (imgId, product_images) => {
    product_images.map((data) => {
      if (data.image_id == imgId) {
        setImage(data.src);
      }
    });
  };

  const plusQtdProduct = () => {
    if (product.stockControl) {
      if(hasVariations()) {
        if (currentVariation.currentStock > quantity) {
          plusQty(product);
        }
      }else{
        if (product.currentStock > quantity) {
          plusQty(product);
        }
      }
    } else {
      plusQty(product);
    }
  };

  const onClickVariation = (variation) => {
    if (!variation.enabled) {
      return;
    }
    if (product.stockControl && variation.currentStock <= 0) {
      return;
    }
    setCurrentVariation(variation);
  };

  const addCart = () => {
    if(productOff){
      toast.error(t('This product is unavailable'));
      return;
    }
    if (hasVariations() && !currentVariation) {
      toast.error(t('Select a variation before'));
      return;
    }
    !productOff &&
      cartContext.addToCart(
        product,
        quantity,
        currentVariation
      );
  }

  const getSalePrice = () => {
    if (currentVariation) {
      return currentVariation.salePrice;
    }
    return product.salePrice;
  };

  const getPromoPrice = () => {
    if (currentVariation) {
      return currentVariation.promoPrice;
    }
    return product.promoPrice;
  };

  const getMaxStock = () => {
    if(!product.stockControl){
      return null;
    }
    if (currentVariation) {
      return currentVariation.currentStock;
    }
    return product.currentStock;
  };

  const productOff =
    !product.enabled ||
    (product.controlStock &&
      product.currentStock <= 0 &&
      store.outStockProduct) == 'u';

  return (
    <div className="product-box product-wrap">
      <div className="img-wrapper">
        <Link href={`/product/${product.slug}`}>
          <a>
            <div className="front">
              {product.images.length > 0 ? (
                <Media
                  src={`${
                    image ? image : getImageDownloadUrl(product.images[0])
                  }`}
                  className="product-image"
                  alt=""
                />
              ) : (
                <div className="product-image no-picture">
                  <i className="fa fa-image"></i>
                </div>
              )}
            </div>
          </a>
        </Link>

        <div className={cartClass}>
          {!productOff && isOpen() && (
            <>
              {!store.onlyCatalog && (
                <button
                  title={t('Add to cart')}
                  onClick={() => {
                    addCart();
                  }}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                </button>
              )}

              <button title={t('Quick View')} onClick={toggle}>
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </>
          )}
        </div>
      </div>
      <MasterProductDetail
        product={product}
        productDetail={productDetail}
        uniqueTags={uniqueTags}
        title={title}
        des={des}
        variantChangeByColor={variantChangeByColor}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-lg quickview-modal"
        centered
      >
        <ModalBody>
          <Row>
            <Col lg="6" xs="12">
              <div className="quick-view-img">
                {product.images.length > 0 ? (
                  <Media
                    src={`${getImageDownloadUrl(product.images[0])}`}
                    alt=""
                    className="product-image"
                  />
                ) : (
                  <div className="product-image no-picture">
                    <i className="fa fa-image"></i>
                  </div>
                )}
              </div>
            </Col>
            <Col lg="6" className="rtl-text">
              <div className="product-right">
                <h2> {product.name} </h2>
                {product.code ? <span>{t('Cod')}: {product.code}</span> : ''}
                {getPromoPrice() ? (
                  <>
                    <h4>
                      <del>{formatValue(getSalePrice(), store.currency)}</del>
                      <span className="badge badge-danger ml-2">-{discount}%</span>
                    </h4>
                    <h3>{formatValue(getPromoPrice(), store.currency)}</h3>
                  </>
                ) : (
                  <h3>{formatValue(getSalePrice(), store.currency)}</h3>
                )}
                <div className="border-product box-description">
                  <h6 className="product-title">{t('Product details')}</h6>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} />
                </div>
                
                  <div className="product-description border-product">
                    {hasVariations() && (
                      <>
                        <h5 className="product-title mt-3 mb-2">{t('Variations')}</h5>
                        <div className="variations-box">
                          <ul>
                            {product.variations.map((variation) => (
                              <li
                                key={variation.id}
                                onClick={() => onClickVariation(variation)}
                                className={`${!variation.enabled ? 'disabled' : ''} ${
                                  product.stockControl && variation.currentStock <= 0
                                    ? 'disabled'
                                    : ''
                                } ${
                                  currentVariation != null &&
                                  variation.id == currentVariation.id
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <a>{variation.name}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    )}
                    {!store.onlyCatalog && ( <>
                    <h6 className="product-title">{t('Quantity')}</h6>
                    <div className="qty-box">
                      <div className="input-group">
                        <span className="input-group-prepend">
                          <button
                            type="button"
                            className="btn quantity-left-minus"
                            onClick={minusQty}
                            data-type="minus"
                            data-field=""
                          >
                            <i className="fa fa-angle-left"></i>
                          </button>
                        </span>
                        <input
                          type="text"
                          name="quantity"
                          value={quantityStr}
                          onChange={changeQty}
                          max={getMaxStock}
                          className="form-control input-number"
                        />
                        <span className="input-group-prepend">
                          <button
                            type="button"
                            className="btn quantity-right-plus"
                            onClick={() => plusQtdProduct(product)}
                            data-type="plus"
                            data-field=""
                          >
                            <i className="fa fa-angle-right"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                    </>
                  )}
                </div>
                
                <div className="product-buttons">
                  {!store.onlyCatalog && (
                    <button
                      className="btn btn-solid"
                      onClick={() => addCart()}
                    >
                      {t('Add to cart')}
                    </button>
                  )}
                  <Link href={`/product/${product.slug}`} passHref>
                    <button className="btn btn-solid">
                      {t('View detail')}
                    </button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProductItem;
